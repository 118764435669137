import React, { useMemo, useState } from "react";
import { formatPrice } from "../../../features/string";
import { ProductCartButton } from "./ProductCartButton";
import styles from "./ProductDetails.module.scss";
import {
  ImageSize,
  ProductDetailsQuery,
  ProductItem,
} from "../../../__generated__/graphql";
import { InStockView } from "../../InStockView/InStockView";
import { MeasureLabel } from "../../../types/enums";
import "react-image-lightbox/style.css";
import { DEFAULT_CATEGORIES } from "../../TopCategories/categories";
import Lightbox from "react-image-lightbox";

export const ProductDetails = ({ product }: { product: ProductDetailsQuery["productDetails"] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const imagesLg = useMemo(() => product?.images.map((img) => `${process.env.NEXT_PUBLIC_S3_CONTAINER}/product_images/${img.id}_${ImageSize.Lg}.jpeg`) || [], []);

  return (
    <div className={styles.mainContainer}>
      <div style={{maxWidth:'300px', display: product?.top_category || product?.images.length ? 'inherit' : 'none'}}>
        {product?.images.length 
        ? (
          <div className={styles.lightGallery} >
            <button 
              style={{width:'100%', position:'relative', display:'inline-block', maxWidth: 300, gridColumn: 'span 3', border: 0, padding: 0}}
              onClick={() => {setIsOpen(true), setImageIndex(0)}}
            >
              <img className={styles.imageGallery} alt={product.title} src={`${process.env.NEXT_PUBLIC_S3_CONTAINER}/product_images/${product?.images[0].id}_${ImageSize.Lg}.jpeg`} />
            </button>
            {product?.images.slice(1).map((img, i) => (
              <button
                onClick={() => {setIsOpen(true), setImageIndex(i + 1)}}
                style={{display: 'inline-block',  width:'100%', position:'relative', height: 90, border: 0, padding: 0}}
                key={img.id}
              >
                <img className={styles.imageGallery} alt={product.title} src={`${process.env.NEXT_PUBLIC_S3_CONTAINER}/product_images/${img.id}_${ImageSize.Sm}.jpeg`} />
              </button>
            ))}
          </div>
        )
        : (
          product?.top_category && 
          <span style={{display:'inline-flex', alignItems:'center', justifyContent: 'center',  width:'100%', maxWidth: 150, aspectRatio: 1, background: '#e3e3e3'}}>
            <svg width="60%" height="60%" style={{background: `url(/icons/catalog/${DEFAULT_CATEGORIES.find((cat) => Number(cat.id) === product.top_category)?.uid}.svg)`, filter: 'invert(60%) sepia(4%) saturate(9%) hue-rotate(314deg) brightness(89%) contrast(90%)'}} />
          </span>
        )}
      </div>
      {isOpen && 
        <Lightbox
          clickOutsideToClose={true}
          enableZoom={false}
          mainSrc={imagesLg[imageIndex]}
          nextSrc={imagesLg[(imageIndex + 1) % imagesLg.length]}
          prevSrc={imagesLg[(imageIndex + imagesLg.length - 1) % imagesLg.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setImageIndex((imageIndex + imagesLg.length - 1) % imagesLg.length)}
          onMoveNextRequest={() => setImageIndex((imageIndex + 1) % imagesLg.length)}
        />
      }
      <div>
        <div className={styles.container}>
          <div className={styles.title}>
            <h4>Наименование</h4>
            <span>{product?.title}</span>
          </div>
          <div className={styles.code}>
            <h4>Артикул</h4>
            <span>{product?.vendor_code || "Не указан"}</span>
          </div>
          {product?.description && (
            <div className={styles.description}>
              <h4>Характеристики, описание</h4>
              <span>{product.description || "Не указаны"}</span>
            </div>
          )}
          {/* <div>
            <h4>Город</h4>
            <span>{product.store.city.name}</span>
          </div> */}
          <div>
            <h4>Наличие</h4>
            <InStockView
              amount={product!.amount}
              supplyDays={product?.supply_days}
            />
          </div>
          <div>
            <h4>Измерение</h4>
            <span>
              {product?.measure ? MeasureLabel[product.measure] : "Не указано"}
            </span>
          </div>
          <div>
            <h4>Цена</h4>
            <span>{formatPrice(Number(product?.price))}</span>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <ProductCartButton product={product as ProductItem} />
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
