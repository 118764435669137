import Link from 'next/link';
import { DEFAULT_CATEGORIES } from './categories';
import styles from './TopCategories.module.scss';


type Props = {
  categories?: typeof DEFAULT_CATEGORIES
}

export function TopCategories({ categories = DEFAULT_CATEGORIES }: Props) {
  return (
    <ul className={styles.catalogMenu}>
      {categories.map((category) => (
        <li key={category.id}>
          <Link href={`/catalog/${category.slug}`}>
              <span
                className={styles.catalogItemIcon}
                style={{ backgroundImage: `url(/icons/catalog/${category.uid}.svg)` }}
              />
              {category.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}
