import { AxiosResponse } from 'axios';
import React from 'react';
import { useDownloadFile } from '../../hooks';
import { Button } from '../Button/Button';

type Props = {
  apiDefinition: () => Promise<AxiosResponse<Blob>>;
  title: string;
  size?: 'medium' | 'small';
  onError?: () => void;
  getFileName?: () => string;
  disabled?: boolean;
};

export const DownloadButton = (props: Props) => {
  const { apiDefinition, title, size, getFileName, onError, disabled = false } = props;

  const { download, ref, url, name, request: fileDownloading } = useDownloadFile({
    apiDefinition,
    getFileName,
    onError
  });

  return (
    <div>
      <a href={url} download={name} style={{ display: 'none' }} ref={ref}>link</a>
      <Button disabled={disabled} variant='primary' title={title} size={size} loading={fileDownloading} onClick={download} />
    </div>
  );
};

export default DownloadButton;