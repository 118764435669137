import { MouseEvent, ReactNode, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { formatPrice } from '../../../features/string';
import { ProductCartButton } from '../components/ProductCartButton';
import { Modal } from '../../Modal/Modal';
import { PRODUCT_DETAILS_QUERY } from '../../../api/public/products';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';
import { ProductDetails } from './ProductDetails';
import { ProductDetailsSkeleton } from './ProductDetailsSkeleton';

import styles from '../ProductsList.module.scss';
import Link from 'next/link';
import { isMobile } from 'react-device-detect';
import { GetProductsQuery, ImageSize, ProductItem } from '../../../__generated__/graphql';
import { InStockView } from '../../InStockView/InStockView';
import { MeasureLabel } from '../../../types/enums';
import { DEFAULT_CATEGORIES } from '../../TopCategories/categories';

type Props = {
  products: GetProductsQuery['products']['data'];
  actionBtn?: (p: ProductItem) => ReactNode;
  parentCategoryId?: number;
}

export const ProductsListTable = (props: Props) => {
  const { products, actionBtn, parentCategoryId } = props;

  const [showDetails, setShowDetails] = useState<boolean>(false);
  
  const [loadProductDetails, {loading, error, data}] = useLazyQuery(PRODUCT_DETAILS_QUERY);
  
  const handleShowDetails = (e: MouseEvent<HTMLAnchorElement>, id: string) => {
    e.preventDefault();
    loadProductDetails({variables: {
      productId: id
    }});
    setShowDetails(true);
  };

  const handleSVGIcon = (categoryId: number | undefined | null) => {
    const SVGId = DEFAULT_CATEGORIES.find((cat) => Number(cat.id) === categoryId);
    return SVGId ? (
      <span style={{display:'flex', alignItems: 'center', justifyContent: 'center', width:'38px', height:'38px', background: '#e3e3e3'}}>
        <svg width="23" height="23" viewBox="0 0 23 23" style={{background: `url(/icons/catalog/${DEFAULT_CATEGORIES.find((cat) => Number(cat.id) === categoryId)?.uid}.svg)`, filter: 'invert(60%) sepia(4%) saturate(9%) hue-rotate(314deg) brightness(89%) contrast(90%)'}} />
      </span>
    ) : (
      <span style={{display:'flex', alignItems: 'center', justifyContent: 'center', width:'38px', height:'38px', background: '#e3e3e3'}}>
        <svg width="23" height="23" viewBox="0 0 23 23" style={{filter: 'invert(60%) sepia(4%) saturate(9%) hue-rotate(314deg) brightness(89%) contrast(90%)'}} />
      </span>
    );
  };

  return (
    <>
      {!isMobile ? 
        <table className={styles.productTable}>
          <thead>
            <tr>
              <th></th>
              <th>Наименование</th>
              <th>Изм.</th>
              <th>Цена</th>
              <th>Наличие</th>
              {/* <th>Склад</th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => {
              const image = product.images?.find((img) => img.sort_order === 0);
              return (
                <tr key={product.id}>
                  {parentCategoryId ? 
                    <td>
                      {image 
                        ? <img style={{objectFit: 'cover'}} alt='' width={38} height={38} src={`${process.env.NEXT_PUBLIC_S3_CONTAINER}/product_images/${image?.id}_${ImageSize.Sm}.jpeg`} />
                        : handleSVGIcon(parentCategoryId)
                      }
                    </td>
                    : 
                    <td>
                      {image 
                        ? <img style={{objectFit: 'cover'}} alt='' width={38} height={38} src={`${process.env.NEXT_PUBLIC_S3_CONTAINER}/product_images/${image?.id}_${ImageSize.Sm}.jpeg`} />
                        : handleSVGIcon(product.top_category)
                      }
                    </td>
                  }
                  <td>
                    <Link onClick={(e) => handleShowDetails(e, product.id)} href={`/product/${product.id}`} className={styles.title}>
                      {product.title}
                    </Link>
                  </td>
                  <td>{product.measure ? MeasureLabel[product.measure] : ''}</td>
                  <td className={styles.noWrap}>{formatPrice(product.price)}</td>
                  <td className={styles.noWrap}><InStockView amount={product.amount} supplyDays={product.supply_days} /></td>
                  {/* <td>{product.store.city.name}</td> */}
                  <td>
                    { actionBtn ? actionBtn(product as ProductItem) : <ProductCartButton product={product as ProductItem} />}
                  </td>
              </tr>
              );
            })}
          </tbody>
        </table> 
      : 
      <div className={styles.productMobileList}>
        {products.map((product) => (
          <div key={product.id} className={styles.product}>
            <div className={styles.title}>
              <Link onClick={(e) => handleShowDetails(e, product.id)} href={`/product/${product.id}`} className={styles.title}>
                {product.title}
              </Link>
            </div>
            <div className={styles.infoRow}>
              <div className={styles.price}>{formatPrice(product.price)}</div>
              <div className={styles.amount}><InStockView amount={product.amount} supplyDays={product.supply_days} /></div>
              {/* <div className={styles.store}>{product.store.city.name}</div> */}
            </div>
            { actionBtn ? actionBtn(product as ProductItem) : <ProductCartButton product={product as ProductItem} />}
          </div>
        ))}
      </div>
      }
      <Modal
        containerClassName={styles.modal}
        show={showDetails}
        onClose={() => setShowDetails(false)}
        title="Описание"
      >
        {loading || !data ? (
          <ProductDetailsSkeleton />
        ) : error ? (
          <ErrorAlert error={error} />
        ) : (
          <ProductDetails product={data.productDetails} />
        )}
      </Modal>
    </>
  );
};
