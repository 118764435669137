import clsx from 'clsx';
import { useRouter } from 'next/router';
import { Button } from '..';
import { formatPrice } from '../../features/string';
import { formatDateMonth } from '../../features/date';

import styles from './OrdersListTable.module.scss';
import { CabinetContentLayout } from '../../components';
import OrderStatusChip from '../OrderStatus/OrderStatusСhip';
import { OrdersQuery } from '../../__generated__/graphql';

export const OrdersListTable = ({ orders }: { orders: OrdersQuery['orders']['data'] }) => {
  const router = useRouter();

  return (
    <>
      {orders.length > 0 ? (
        <CabinetContentLayout>
          <div>
            <div className={styles.row}>
              <div className={styles.item}>
                <span className={styles.header}>Номер</span>
              </div>
              <div className={styles.item}>
                <span className={styles.header}>Дата</span>
              </div>
              <div className={styles.item}>
                <span className={styles.header}>Сумма</span>
              </div>
              <div className={styles.item}>
                <span className={styles.header}>Статус</span>
              </div>
            </div>
            <div className={styles.ordersContainer}>
              {orders.map((o) => (
                <div className={clsx(styles.row, styles.order)} key={o.num}>
                  <div className={styles.item}>
                    <span>{o.num}</span>
                  </div>
                  <div className={styles.item}>
                    <span>{formatDateMonth(new Date(o.created_at))}</span>
                  </div>
                  <div className={styles.item}>{formatPrice(o.summ)}</div>
                  <div className={styles.item}>
                    <OrderStatusChip status={o.status} />
                  </div>
                  <div className={styles.orderBtn}>
                    <Button
                      onClick={() => {
                        router.push(`/cabinet/orders/${o.id}`);
                      }}
                      title='Открыть'
                      variant='primary'
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </CabinetContentLayout>
      ) : (
        <div>Вы еще ничего не заказывали</div>
      )}
    </>
  );
};

export default OrdersListTable;
