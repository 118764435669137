import React from 'react';

type Props = {
  fill?: string;
  height?: number;
  width?: number;
}

const ArrowIcon = ({ fill, height = 19, width = 22 } : Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 19" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.5C0 9.14013 0.14485 8.795 0.402683 8.54053C0.660517 8.28606 1.01021 8.1431 1.37485 8.1431H17.3038L11.4002 2.3193C11.1421 2.06451 10.997 1.71894 10.997 1.35861C10.997 0.998286 11.1421 0.652718 11.4002 0.397929C11.6584 0.143139 12.0085 0 12.3736 0C12.7387 0 13.0889 0.143139 13.347 0.397929L21.5961 8.53932C21.7241 8.66536 21.8257 8.8151 21.895 8.97995C21.9643 9.1448 22 9.32152 22 9.5C22 9.67848 21.9643 9.8552 21.895 10.0201C21.8257 10.1849 21.7241 10.3346 21.5961 10.4607L13.347 18.6021C13.0889 18.8569 12.7387 19 12.3736 19C12.0085 19 11.6584 18.8569 11.4002 18.6021C11.1421 18.3473 10.997 18.0017 10.997 17.6414C10.997 17.2811 11.1421 16.9355 11.4002 16.6807L17.3038 10.8569H1.37485C1.01021 10.8569 0.660517 10.7139 0.402683 10.4595C0.14485 10.205 0 9.85987 0 9.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowIcon;
