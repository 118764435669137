import ReactPagination from 'react-js-pagination';

type Props = {
  activePage: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
  onChange: (p: number) => void;
}

export function Pagination(props: Props) {
  return (
    <ReactPagination
      {...props}
      hideFirstLastPages
      innerClass="bl-pagination"
    // itemClassPrev="bl-pagination__prev"
    // itemClassNext="bl-pagination__next"
    // itemClassFirst="bl-pagination__first"
    // itemClassLast="bl-pagination__last"
    />
  );
}
