import Head from 'next/head';
import Script from 'next/script';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import styles from './Page.module.scss';

type Props = {
  fullWidthContent?: boolean;
  showMainBanner?: boolean;
  children: JSX.Element[] | JSX.Element;
  keywords?: string;
  description?: string;
  showMainBannerTotalProductsCount?: number; 
}

const Page = (props: Props) => {
  const { fullWidthContent, showMainBanner, children, keywords, description, showMainBannerTotalProductsCount } = props;
  return (
    <div className={styles.root}>
      <Head>
        <title itemProp="headline">Все инженерные системы</title>
        <link rel="canonical" href="https://ensys.store" />
        <meta itemProp="description" name="description" content={description} />
        <meta itemProp="keywords" name="keywords" content={keywords} />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Header showMainBannerTotalProductsCount={showMainBannerTotalProductsCount} showMainBanner={showMainBanner} />

      <main className={styles.mainContainer}>
        <div className={!fullWidthContent ? styles.main : ''}>
          {children}
        </div>
      </main>

      <Footer />

      {process && process.env.NODE_ENV == "production" && <>
        <Script
          id="ya-metrika"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
             (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
             m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
             (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
             ym(88656239, "init", {
                  clickmap:true,
                  trackLinks:true,
                  accurateTrackBounce:true,
                  webvisor:true
             });
          `,
          }}
        />
        <img src="https://mc.yandex.ru/watch/88656239" style={{'position':'absolute', 'left':'-9999px'}} alt="" />
        {/*<Script type="text/javascript"
          id="chat-widget"
          dangerouslySetInnerHTML={{
            __html: `
            document.umnicoWidgetHash = '62e2e8704410d5dfe8f181f2e6123bd8';
            var x = document.createElement('script');
            x.src = 'https://umnico.com/assets/widget-loader.js';
            x.type = 'text/javascript';
            x.charset = 'UTF-8';
            x.async = true;
            document.body.appendChild(x);
            `
          }}
        />*/}
      </>}

    </div>
  );
};

export { Page };
