import React, { useState } from 'react';
import BillingEditForm from '../../../forms/Company/forms/BillingEditForm/BillingEditForm';
import { Button } from '../../Button/Button';
import ItemCard from '../../ItemCard/ItemCard';
import { Modal } from '../../Modal/Modal';

import styles from './BillingCard.module.scss';
import { CompanyQuery } from '../../../__generated__/graphql';

type Props = {
  billing: CompanyQuery['companyBillings'][0];
  onEdit: () => void;
};

const BillingCard = ({ billing, onEdit }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <ItemCard>
        <div className={styles.billingCard}>
          <span className={styles.billingInfo}>
            {billing.bank_name ? `${billing.account}, ${billing.bank_name}` : billing.account}
          </span>
          <div className={styles.billingStatus}>
            {billing.is_active ? (
              <span className={styles.active}>Активен</span>
            ) : (
              <span>Не активен</span>
            )}
          </div>
          <div className={styles.openBtn}>
            <Button title='Открыть' variant='primary' onClick={() => setShowModal(true)} />
          </div>
        </div>
      </ItemCard>
      <Modal title="Реквизиты" show={showModal} onClose={() => setShowModal(false)}>
        <BillingEditForm
          billing={billing}
          onEdit={() => {
            onEdit();
            setShowModal(false);
          }}
        />
      </Modal>
    </>
  );
};

export default BillingCard;
