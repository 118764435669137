import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { useDebouncedState } from '../../../hooks';
import { TextField } from '../TextField/TextField';

import styles from './Autocomplete.module.scss';

export type AutocompleteProps<T> = {
  value: T | null;
  id?: string;
  name?: string;
  placeholder?: string;
  fetch: (query: string) => Promise<any[] | T[]>;
  getLabel: (v: T) => string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onSelect: (v: T | null) => void;
};

export function Autocomplete<T extends { id: number | string }>(props: AutocompleteProps<T>) {
  const { id, name, value, placeholder, onBlur, getLabel, fetch, onSelect } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<T[]>([]);
  const [queryInput, setQueryInput] = useState<string>('');
  const [showList, setShowList] = useState<boolean>(false);

  const query = useDebouncedState(queryInput, 500);

  useEffect(() => {
    setLoading(true);
    fetch(query)
      .then((r) => {
        setLoading(false);
        setOptions(r);
      })
      .catch(() => {
        setLoading(false);
      });
    //eslint-disable-next-line
  }, [query]);

  const handleBlur = (e: React.FocusEvent<any>) => {
    if (onBlur) {
      onBlur(e);
    }
    setShowList(false);
  };

  const onChange = (e: React.ChangeEvent<any>) => {
    setQueryInput(e.target.value);
  };

  const handleSelect = (v: T) => {
    onSelect(v);
    setQueryInput('');
  };

  const handleFocus = () => {
    if (!value) {
      setShowList(true);
    }
  };

  const handleClearInput = () => {
    setQueryInput('');
    onSelect(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.input}>
        <TextField
          id={id}
          name={name}
          value={value ? getLabel(value) : queryInput}
          autoComplete={'off'}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          readOnly={Boolean(value)}
        />
        <button type="button" onClick={handleClearInput} className={styles.input_clear}>
          <Image src="/svg/close.svg" width={10} height={10} alt="" />
        </button>
      </div>
      {showList ? (
        <div className={styles.list_container}>
          <ul>
            {loading && (
              <li className={`${styles.disabled} ${styles.loadBox}`}>
                <div className={styles.loading}></div>
              </li>
            )}
            {!loading && options.length === 0 && <li className={styles.disabled}>Нет совпадений</li>}
            {!loading &&
              options?.map((c) => (
                <li key={c.id} onClick={() => handleSelect(c)} onMouseDown={() => handleSelect(c)}>
                  {getLabel(c)}
                </li>
              ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
}
