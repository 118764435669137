import { useState, useEffect } from 'react';
import { FileDrop } from 'react-file-drop';
import clsx from 'clsx';
import { uploadFile } from '../../../api/public/file';
import styles from './FileUpload.module.scss';

type FileItemProp = {
  file: File;
  status: 'uploading' | 'uploaded' | 'error';
  error?: string;
  id?: string;
}

function FileItem({ file, onDelete }: { file: FileItemProp, onDelete: () => void }) {
  return (
    <div className={clsx(styles.fileItem, file.error && styles.fileItemError)}>
      <div className={styles.fileInfoContainer}>
        {file.status === 'uploading' && <span className={styles.spin} />}
        {file.file.name}
        {file.status !== "uploading" && <button onClick={onDelete}>удалить</button>}
      </div>
      {file.error ? (
        <div className={styles.error}>{file.error}</div>
      ) : null}
    </div>
  );
}

type Props = {
  onChange: (files: string) => void;
  setIsUploading?: (asd: boolean) => void;
};

const MAX_SIZE = 50 * 1024 * 1024;

export function FileUpload(props: Props) {
  const { onChange, setIsUploading } = props;
  const [files, setFiles] = useState<Array<FileItemProp>>([]);

  const handleDropFiles = (dropFiles: FileList | null) => {
    if (dropFiles === null) {
      return;
    }
    setFiles([
      ...files,
      ...Array.from(dropFiles).map((f) => ({
        status: f.size <= MAX_SIZE? 'uploading' : 'error',
        error: f.size > MAX_SIZE ? 'Размер файла не должен превышать 50мб' : undefined,
        file: f,
      }) as FileItemProp)
    ]);
  };

  useEffect(() => {
    const uploadFiles = async () => {
      const uploads = files.map(async (f) => {
        if (f.status === 'uploading') {
          setIsUploading && setIsUploading(true);
          
          try {
            const response = await uploadFile(f.file);
            f.status = 'uploaded';
            f.id = response.data.filename;
          } catch (error: any) {
            f.status = 'error';
            f.error = error.message;
          }
          onChange(files
            .filter((file) => file.status === 'uploaded' && file.id)
            .map((file) => `${file.id},${file.file.name.replace(',', '').replace(';', '')}`)
            .join(';')
          );
        }
      });
  
      await Promise.all(uploads);
      setIsUploading && setIsUploading(false);
      setFiles([...files]);
    };
  
    if (files.filter((f) => f.status !== 'uploaded').length > 0) {
      uploadFiles();
    }
  }, [files]);

  const handleDelete = (idx: number) => () => {
    const a = [...files];
    a.splice(idx, 1);
    setFiles(a);
  };

  return (
    <div className={styles.filesDropContainer}>
      <FileDrop
        // onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
        // onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
        // onFrameDrop={(event) => console.log('onFrameDrop', event)}
        // onDragOver={(event) => console.log('onDragOver', event)}
        // onDragLeave={(event) => console.log('onDragLeave', event)}
        onDrop={handleDropFiles}
      >
        {files.length === 0 ? (
          <div className={styles.noFiles}>Перетащите сюда файлы</div>
        ) : (
          files.map((f, idx) => <FileItem file={f} key={idx} onDelete={handleDelete(idx)} />)
        )}
      </FileDrop>
    </div>
  );
}
