import React from 'react';
import styles from './OrderItems.module.scss';
import clsx from 'clsx';
import { formatPrice } from '../../../features/string';
import { OrderItemStatusChip } from './OrderItemStatusChip';
import { Button } from '../../Button/Button';
import { OrderItemStatusId } from '../../../types/enums';
import { isMobile } from 'react-device-detect';
import { OrderItemStatus, OrderQuery } from '../../../__generated__/graphql';
import { InStockView } from '../../InStockView/InStockView';

type Props = {
  orderItems: OrderQuery['order']['items'];
  updateOrderItem: (variables: {variables: {status: OrderItemStatusId, orderItemId: string}}) => void;
  isAllOrderItemsChecked: boolean;
}

export const OrderItems = (props : Props) => {
  const { orderItems, updateOrderItem, isAllOrderItemsChecked } = props;
  return (
    <>
      {orderItems.map((i) => {
        const originValues = i.orderItem.request_proposal_item || i.productItem;
        const isPriceOutdated = originValues.price !== i.orderItem.price,
          isStoreAmountOutdated = originValues.amount !== i.orderItem.store_amount,
          isSupplyDaysOutdated = (originValues.supply_days || 0) !== (i.orderItem.supply_days || 0),
          title = i.productItem.title,
          originPrice = formatPrice(originValues.price),
          price = formatPrice(i.orderItem.price),
          originAmount = originValues.amount,
          amount = i.orderItem.store_amount,
          originSupplayDays = originValues.supply_days,
          supplayDays = i.orderItem.supply_days,
          city = i.productItem.store.city.name,
          pos = i.orderItem.position_num;

        return isMobile ? (
          <div className={styles.mobileContainer} key={i.productItem.title}>
            <span className={styles.header}>№</span>
            <div className={styles.item}>
              <span>{pos}</span>
            </div>
            <span className={styles.header}>Наименование</span>
            <div className={styles.item}>
              <span>{title}</span>
            </div>
            <span className={styles.header}>Цена</span>
            <div className={styles.item}>
              <span className={clsx(isPriceOutdated && styles.itemOutdated)}>{originPrice}</span>
              {isPriceOutdated && <span className={styles.itemNew}>{price}</span>}
            </div>
            <span className={styles.header}>Наличие</span>
            <div className={styles.item}>
              <span className={clsx(isStoreAmountOutdated && styles.itemOutdated)}>
                <InStockView show='amount' amount={originAmount} supplyDays={originSupplayDays} />
              </span>
              {isStoreAmountOutdated && 
              <span className={styles.itemNew}>
                <InStockView show='amount' amount={amount} supplyDays={supplayDays} />
              </span>}
              <span className={clsx(isSupplyDaysOutdated && styles.itemOutdated)}>
                <InStockView show='supplyDays' amount={originAmount} supplyDays={originSupplayDays} />
              </span>
              {isSupplyDaysOutdated && 
              <span className={styles.itemNew}>
                <InStockView show='supplyDays' amount={amount} supplyDays={supplayDays} />
              </span>}
            </div>
            <span className={styles.header}>Склад</span>
            <div className={styles.item}>
              <span>{city}</span>
            </div>
            {isAllOrderItemsChecked && 
              <>
                <span className={styles.header}>Статус</span>
                <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}} className={styles.item}>
                  <OrderItemStatusChip status={i.orderItem.status} />
                  {i.orderItem.status === OrderItemStatus.Changed && 
                    <div style={{display:'flex', gap:'10px', marginTop:'10px'}}>
                      <Button onClick={() => updateOrderItem({variables: {orderItemId: i.orderItem.id, status: OrderItemStatusId.REJECTED}})} title='Удалить' size='small' textColor='danger' />
                      <Button onClick={() => updateOrderItem({variables: {orderItemId: i.orderItem.id, status: OrderItemStatusId.APPROVED}})} title='Принять' size='small' />
                    </div>
                  }
                </div>
              </>
            }
            <span className={styles.header}>Кол-во</span>
            <div className={clsx(styles.item, styles.amountItem)}>
              <span>{i.orderItem.amount}</span>
            </div>
            <span className={styles.header}>Итого</span>
            <div className={clsx(styles.item, styles.amountItem)}>
              <span className={clsx(isPriceOutdated && styles.itemOutdated)}>{formatPrice(i.orderItem.amount * i.productItem.price)}</span>
              {(i.orderItem.status !== OrderItemStatus.Rejected && isPriceOutdated) && <span className={styles.itemNew}>{formatPrice(i.orderItem.amount * i.orderItem.price)}</span>}
              {i.orderItem.status === OrderItemStatus.Rejected && <span className={styles.itemNew}>{formatPrice(0)}</span>}
            </div>
          </div>
        ) : (
          <React.Fragment key={i.productItem.title}>
            <div className={styles.item}>
              <span>{pos}</span>
            </div>
            <div className={styles.item}>
              <span>{title}</span>
            </div>
            <div className={styles.item}>
              <span className={clsx(isPriceOutdated && styles.itemOutdated)}>{originPrice}</span>
              {isPriceOutdated && <span className={styles.itemNew}>{price}</span>}
            </div>
            <div className={styles.item}>
              <span className={clsx(isStoreAmountOutdated && styles.itemOutdated)}>
                <InStockView show='amount' amount={originAmount} supplyDays={originSupplayDays} />
              </span>
              {isStoreAmountOutdated && 
              <span className={styles.itemNew}>
                <InStockView show='amount' amount={amount} supplyDays={supplayDays} />
              </span>}
              <span className={clsx(isSupplyDaysOutdated && styles.itemOutdated)}>
                <InStockView show='supplyDays' amount={originAmount} supplyDays={originSupplayDays} />
              </span>
              {isSupplyDaysOutdated && 
              <span className={styles.itemNew}>
                <InStockView show='supplyDays' amount={amount} supplyDays={supplayDays} />
              </span>}
            </div>
            <div className={styles.item}>
              <span>{city}</span>
            </div>
            {isAllOrderItemsChecked && 
              <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}} className={styles.item}>
                <OrderItemStatusChip status={i.orderItem.status as OrderItemStatus} />
                {i.orderItem.status === OrderItemStatus.Changed && 
                  <div style={{display:'flex', gap:'10px', marginTop:'10px'}}>
                    <Button onClick={() => updateOrderItem({variables: {orderItemId: i.orderItem.id, status: OrderItemStatusId.REJECTED}})} title='Удалить' size='small' textColor='danger' />
                    <Button onClick={() => updateOrderItem({variables: {orderItemId: i.orderItem.id, status: OrderItemStatusId.APPROVED}})} title='Принять' size='small' />
                  </div>
                }
              </div>
            }
            <div className={clsx(styles.item, styles.amountItem)}>
              <span>{i.orderItem.amount}</span>
            </div>
            <div className={clsx(styles.item, styles.amountItem)}>
              <span className={clsx(isPriceOutdated && styles.itemOutdated)}>{formatPrice(i.orderItem.amount * originValues.price)}</span>
              {(i.orderItem.status !== OrderItemStatus.Rejected && isPriceOutdated) && <span className={styles.itemNew}>{formatPrice(i.orderItem.amount * i.orderItem.price)}</span>}
              {i.orderItem.status === OrderItemStatus.Rejected && <span className={styles.itemNew}>{formatPrice(0)}</span>}
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};
