import Link from 'next/link';
import {useConstants} from '../../hooks';
import styles from './Footer.module.scss';

export default function Footer() {
  const {contacts: { phones, emails }} = useConstants();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.colCompany}>
          <h3>Компания</h3>
          <p><Link href="/about">О компании</Link></p>
          <p><Link href="/contacts">Контакты</Link></p>
          <p><Link href="/invest">Инвесторам</Link></p>
          <p><Link href="/vacancies">Вакансии</Link></p>
        </div>
        <div className={styles.colInfo}>
          <h3>Партнёрам</h3>
          <p><Link href="https://partner.ensys.store/" target="_blank">
            Партнёрская программа
          </Link></p>
          <p><Link href="https://partner.ensys.store/auth/login" target="_blank">
            Вход в личный кабинет
          </Link></p>
        </div>
        <div className={styles.colSecond}>
          <h3>Покупателям</h3>
          {/*<p><Link href="/for-clients"><a>Информация</a></Link></p>*/}
          <p><Link href="/delivery">Условия доставки</Link></p>
          <p><Link href="/payment">Способы оплаты</Link></p>
          <p><Link href="/return">Условия возврата</Link></p>
        </div>
        <div className={styles.colContacts}>
          <h3>Контакты</h3>
          <h5>Отдел обслуживания клиентов</h5>
          <p><Link href={`tel:${phones.main.phone}`}>{phones.main.label}</Link><br/>
          <Link href={`mailto:${emails.sales}`}>{emails.sales}</Link></p>
          <h5>
            Техническая поддержка
          </h5>
          <p>
            <Link href={`mailto:${emails.support}`}>{emails.support}</Link>
          </p>
        </div>
      </div>
      <div className={styles.rightsContent}>
        <div className={styles.copyright}>
          © 2022-2024 Инженерный маркетплейс «Все инженерные системы»
        </div>
        <div className={styles.agreements}>
          Продолжая работу с сайтом, вы даете согласие на использование сайтом cookies и
          {' '}<Link href="/info/privacy-policy">обработку персональных данных</Link>{' '}
          в целях функционирования сайта, проведения ретаргетинга, статистических исследований,
          и улучшения сервиса.
        </div>
      </div>
    </div>
  );
}
