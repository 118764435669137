import clsx from 'clsx';
import styles from './FormField.module.scss';

export type FormFieldProps = {
  id?: string;
  label?: string;
  helperText?: string;
  error?: string;
  required?: boolean;
  children: React.ReactNode;
};

export function FormField(props: FormFieldProps) {
  const { id, label, helperText, error, children, required } = props;
  return (
    <div className={styles.container}>
      {label && (
        <label htmlFor={id} className={clsx(styles.label, error && styles.labelError)}>{`${label} ${
          required ? '*' : ''
        }`}</label>
      )}
      <div>{children}</div>
      {helperText && <div className={styles.helper}>{helperText}</div>}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
}
