import React, { useEffect, useState } from 'react';
import styles from './AddProductButton.module.scss';
import clsx from 'clsx';
import { Button } from '../Button/Button';

type Props = {
  value: number;
  initialValue?: number;
  onChange: (val: number) => void;
  buttonText?: string;
}

export function AddProductButton(props: Props) {
  const {
    value,
    initialValue = 1,
    onChange,
    buttonText = 'Добавить'
  } = props;

  const [controlledValue, setControlledValue] = useState<number>(value);

  useEffect(() => {
    setControlledValue(value);
  }, [value]);

  return value ? (
    <div className={styles.countControl}>
      <button
        className={clsx('btn', styles.btnAmountControl)}
        onClick={() => onChange(value - 1)}
      >
        <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="8" y="18" width="24" height="4" fill="black"/>
        </svg>
      </button>
      <input
        maxLength={15}
        className={styles.countInput}
        value={controlledValue ? String(controlledValue) : ''}
        onChange={(e) => {
          try {
            const v = parseInt(e.target.value, 10);
            setControlledValue(v || 0);
          } catch (e) {
            setControlledValue(0);
          }
        }}
        onBlur={() => {
          onChange(controlledValue);
        }}
      />
      <button
        className={clsx('btn', styles.btnAmountControl)}
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => onChange(value + 1)}
      >
        <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="18" y="8" width="4" height="24" fill="black"/>
          <rect x="8" y="18" width="24" height="4" fill="black"/>
        </svg>
      </button>
    </div>
  ) : (
    <Button
      onClick={() => onChange(initialValue)}
      title={buttonText}
      variant="primary"
      size="small"
    />
  );
}
