import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { TopCategories } from '../TopCategories/TopCategories';

import styles from './Catalog.module.scss';

export const Catalog = () => {
  const router = useRouter();
  const [showCategories, setShowCategories] = useState<boolean>(false);

  const handleShowCategories = () => {
    if (isMobile) {
      setShowCategories((prev) => !prev);
    }
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      setShowCategories(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setShowCategories(false);
    }
  };

  return (
    <div
      className={styles.topCategoriesContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button className={styles.catalogBtn} onClick={handleShowCategories}>
        <Image src="/svg/menu.svg" width={22} height={22} alt="" />
        <span className={styles.catalogBtnText}>{'Каталог'}</span>
      </button>
      {router.pathname !== '/' && showCategories && (
        <div>
          <TopCategories />
        </div>
      )}
    </div>
  );
};

export default Catalog;
