import { useRouter } from 'next/router';
import { Button } from '..';
import { formatPrice } from '../../features/string';
import { formatDateMonth } from '../../features/date';

import styles from './OrdersListMobile.module.scss';
import OrderStatusChip from '../OrderStatus/OrderStatusСhip';
import { OrdersQuery } from '../../__generated__/graphql';

export const OrdersListMobile = ({ orders }: { orders: OrdersQuery['orders']['data'] }) => {
  const router = useRouter();

  return (
    <>
      {orders.length > 0 ? (
        <div className={styles.container}>
          {orders.map((o) => (
            <div className={styles.box} key={o.num}>
              <div className={styles.item}>
                <span className={styles.header}>Номер</span>
                <span>{o.num}</span>
              </div>
              <div className={styles.item}>
                <span className={styles.header}>Дата</span>
                <span>{formatDateMonth(new Date(o.created_at))}</span>
              </div>
              <div className={styles.item}>
                <span className={styles.header}>Сумма</span>
                {formatPrice(o.summ)}
              </div>
              <div className={styles.item}>
                <span className={styles.header}>Статус</span> 
                <OrderStatusChip status={o.status} />
              </div>
              <div className={styles.orderBtn}>
                <Button
                  onClick={() => {
                    router.push(`/cabinet/orders/${o.id}`);
                  }}
                  variant='primary'
                  title='Открыть'
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>Вы еще ничего не заказывали</div>
      )}
    </>
  );
};

export default OrdersListMobile;
