
export const DEFAULT_CATEGORIES = [
  {
    id: '1',
    uid: '1',
    slug: 'otoplenie-i-vodosnabzhenie',
    title: 'Отопление и водоснабжение',
  },
  {
    id: '374',
    uid: '2',
    slug: 'nasosnoe-oborudovanie',
    title: 'Насосное оборудование',
  },
  {
    id: '401',
    uid: '3',
    slug: 'kanalizaciya',
    title: 'Канализация',
  }, {
    id: '429',
    uid: '4',
    slug: 'santehnika',
    title: 'Сантехника',
  }, {
    id: '476',
    uid: '5',
    slug: 'ventilyaciya',
    title: 'Вентиляция',
  }, {
    id: '557',
    uid: '6',
    slug: 'kondicionirovanie-i-holodosnabzhenie',
    title: 'Кондиционирование и холодоснабжение',
  }, {
    id: '598',
    uid: '7',
    slug: 'elektrosnabzhenie',
    title: 'Электроснабжение',
  }, {
    id: '678',
    uid: '8',
    slug: 'vysokovoltnoe-elektrosnabzhenie',
    title: 'Высоковольтное электроснабжение',
  }, {
    id: '679',
    uid: '9',
    slug: 'ohranno-pozharnye-sistemy',
    title: 'Охранно-пожарные системы',
  }, {
    id: '680',
    uid: '10',
    slug: 'strukturirovannye-kabelnye-seti-i-seti-svyazi',
    title: 'Структурированные кабельные сети и сети связи',
  }, {
    id: '681',
    uid: '11',
    slug: 'dispetcherizaciya-i-avtomatizaciya',
    title: 'Диспетчеризация и автоматизация',
  }, {
    id: '682',
    uid: '12',
    slug: 'sistemy-kontrolya-i-upravleniya-dostupom',
    title: 'Системы контроля и управления доступом',
  }, {
    id: '683',
    uid: '13',
    slug: 'gazosnabzhenie',
    title: 'Газоснабжение',
  }, {
    id: '684',
    uid: '14',
    slug: 'instrumenty',
    title: 'Инструменты',
  }
].map((c) => ({
  ...c,
  parent_category: null,
}));
