import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';
import { CitySelect } from '../CitySelect/CitySelect';
import { useConstants } from '../../hooks';
import { useSelector } from '../../store/hooks';
import { selectCartItems } from '../../store/cartSlice';
import { ProductSearch } from './ProductSearch';
import { Catalog } from './Catalog';
import { getToken } from '../../store/loginSlice';
import ArrowIcon from '../Icons/ArrowIcon';

import styles from './Header.module.scss';

type Props = {
  showMainBanner?: boolean;
  showMainBannerTotalProductsCount?: number;
}

export default function Header(props: Props) {
  const { showMainBanner, showMainBannerTotalProductsCount } = props;

  const items = useSelector(selectCartItems);
  const token = useSelector(getToken);

  const {
    contacts: {
      phones: { main: mainPhone },
      emails: { sales: salesEmail },
    },
  } = useConstants();

  return (
    <header>
      <div className={styles.topBlock}>
        <Link href="/" className={styles.logoLink}>

          <Image src="/svg/logo.svg" width={50} height={60} alt="" />
          <div>
            <div className={styles.logoName}>ВСЕ ИНЖЕНЕРНЫЕ СИСТЕМЫ</div>
            <div className={styles.logoHint}>инженерный маркетплейс для профессионалов</div>
          </div>

        </Link>
        {/*
        <div className={styles.topBlockSlogan}>
          Всё для инженерных систем
          <br />
          в одном месте
        </div>
        */}
        <div className={styles.topBlockRightAlignedLinks}>
          <CitySelect />
          <Link href={`tel:${mainPhone.phone}`}>
            <Image src="/svg/phone.svg" width={20} height={20} alt="" />
            <span className={styles.topBlockRightAlignedLinksText}>{mainPhone.label}</span>
          </Link>
          <Link href={`mailto:${salesEmail}`}>
            <Image src="/svg/envelop.svg" width={20} height={20} alt="" />
            <span className={styles.topBlockRightAlignedLinksText}>{salesEmail}</span>
          </Link>
        </div>
      </div>
      {showMainBanner && (
        <div className={styles.sloganBlock}>
          <div className={styles.content}>
            Скомплектуйте на 100% заявку в одном окне
            <span>более {(Math.floor(showMainBannerTotalProductsCount! / 100000) * 100000).toLocaleString('ru-RU')} товаров для инженерных систем по оптовым ценам</span>
          </div>
          <Link href="/start" className={styles.detailsButton}>
            Подробнее<ArrowIcon fill='#3993E5' width={16} height={16} />
          </Link>
        </div>
      )}
      <div className={styles.primaryBlock}>
        <div className={styles.content}>
          <Catalog />
          <ProductSearch />
          <div className={styles.primaryButtons}>
            <Link href="/cart" className={clsx(styles.cartLink, styles.primaryButton)}>
              <Image src="/svg/cart.svg" width={22} height={22} alt="" />
              <span className={clsx(styles.cartLinkText, styles.primaryButtonText)}>
                {'Корзина'}
              </span>
              {items.length > 0 ? (
                <span className={styles.cartLinkCountBadge}>{items.length}</span>
              ) : null}

            </Link>

            <Link href={!token ? '/login' : '/cabinet'} className={clsx(styles.cabinetBtn, styles.primaryButton)}>
              <Image src={!token ? "/svg/user.svg" : "/svg/briefcase.svg"} width={22} height={22} alt="" />
              <span className={clsx(styles.primaryButtonText)}>
                {!token ? 'Вход' : 'Личный кабинет'}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}
