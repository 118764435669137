import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';
import { formatDateMonth } from '../../features/date';
import { Button } from '../Button/Button';
import { CabinetContentLayout } from '../../components';

import styles from './RequestsTable.module.scss';
import { RequestStatusChip } from '../RequestDetails/components/RequestStatusChip';
import { QueryQuery } from '../../__generated__/graphql';

export enum RequestStatus {
  'OPEN' = 'Открыта',
  'CANCELED' = 'Отменена',
  'ORDERED' = 'Заказана',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  'SENDED' = 'Открыта',
  'CLOSED' = 'Закрыта',
  'DRAFT' = 'Черновик'
}

const RequestsTable = ({ requests }: { requests: QueryQuery['requests']['data'] }) => {
  const router = useRouter();

  return (
    <>
      <CabinetContentLayout header="Заявки">
        {requests.length > 0 ? (
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.item}>
                <span className={styles.header}>Номер, название</span>
              </div>
              <div className={styles.item}>
                <span className={styles.header}>Дата</span>
              </div>
              <div className={styles.item}>
                <span className={styles.header}>Статус</span>
              </div>
            </div>
            <div className={styles.ordersContainer}>
              {requests.map((r) => (
                <div
                  className={clsx(
                    styles.row,
                    styles.order,
                  )}
                  key={r.id}
                >
                  <div className={styles.item}>
                    <span>{r.num}{r.title ? `, ${r.title}` : ''}</span>
                  </div>
                  <div className={styles.item}>
                    <span>{formatDateMonth(new Date(r.created_at))}</span>
                  </div>
                  <div className={styles.item}>
                    <RequestStatusChip status={r.status} />
                  </div>
                  <div className={styles.orderBtn}>
                    <Button
                      onClick={() => {
                        router.push(`/cabinet/requests/${r.id}`);
                      }}
                      variant='primary'
                      title='Открыть'
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>Вы еще ничего не заказывали</div>
        )}
      </CabinetContentLayout>
    </>
  );
};

export default RequestsTable;
