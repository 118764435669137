import React, { ButtonHTMLAttributes } from 'react';
import styles from './Button.module.scss';
import clsx from 'clsx';

export type ButtonProps = {
  onClick: () => void;
  loading?: boolean;
  size?: 'medium' | 'small';
  disabled?: boolean;
  variant?: 'primary' | 'default';
  textColor?: 'default' | 'danger';
  icon?: React.ReactNode;
  title: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
}

export function Button (props: ButtonProps) {
  const { onClick, loading, size = 'medium', disabled, variant = 'default', textColor = 'default', icon, title, type = 'button' } = props;

  return (
    <button
      type={type}
      className={clsx(`${styles.btn}`, `${styles[`btn-${size}`]}`, `${styles[`btn-${variant}`]}`)}
      onClick={onClick}
      disabled={loading || disabled}
    >
      <div className={styles.box}>
        {loading && <div className={styles.buttonLoading}></div>}
        {icon}
        <span className={clsx(styles['btn-text'], `${styles[`textColor-${textColor}`]}`)}>{title}</span>
      </div>
    </button>
  );
}
