import { useRouter } from 'next/router';
import React from 'react';
import { Button } from '../Button/Button';
import ItemCard from '../ItemCard/ItemCard';

import styles from './CompaniesList.module.scss';
import { CompaniesQuery } from '../../__generated__/graphql';

type Props = {
  companies: CompaniesQuery['companies'];
};

export const CompaniesList = ({ companies }: Props) => {
  const router = useRouter();

  return companies.length > 0 ? (
    <div className={styles.container}>
      {companies.map((company) => (
        <ItemCard key={company.id}>
          <div className={styles.companyCard}>
            <span className={styles.companyInfo}>{`${company.name}, ИНН ${company.inn}`}</span>
            <div className={styles.openBtn}>
              <Button title='Открыть' variant='primary' onClick={() => router.push(`/cabinet/companies/${company.id}`)} />
            </div>
          </div>
        </ItemCard>
      ))}
    </div>
  ) : (
    <div>Добавьте информацию о своей компании</div>
  );
};

export default CompaniesList;
