import { useEffect } from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import styles from './Modal.module.scss';

type Props = {
  children: JSX.Element[] | JSX.Element;
  show: boolean;
  title?: string;
  onClose?: () => void;
  containerClassName?: string;
}

const Modal = (prop: Props) => {
  const {
    show,
    children,
    onClose,
    title = '',
    containerClassName,
  } = prop;

  useEffect(() => {
    return () => {
      const document = window.document.querySelector('body');
      if (document !== null) {
        document.style.overflow = 'auto';
      }
    };
  }, []);

  useEffect(() => {
    const document = window.document.querySelector('body');
    if (document !== null) {
      document.style.overflow = show ? 'hidden' : 'auto';
    }
  }, [show]);

  const handleClickOutside = (event: React.MouseEvent<HTMLElement>) => {
    // event.preventDefault();
    // console.log(event.currentTarget);
    if (event.target === event.currentTarget && onClose) {
      onClose();
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className={styles.placeholder} onClick={handleClickOutside}>
      <div className={clsx(styles.container, containerClassName)}>
        <div className={styles.header}>
          <h2>{title}</h2>
          {onClose ? (
            <button className={styles.closeBtn} onClick={() => onClose()}>
              <Image src='/svg/close.svg' width={20} height={20} alt="" />
            </button>
          ) : null}
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};

export { Modal };