import { InputHTMLAttributes } from 'react';
import styles from './TextField.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {};

export function TextField(props: Props) {
  return (
    <input
      {...props}
      className={styles.input}
    />
  );
}
