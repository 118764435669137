import { client } from './graphql';
import axios from 'axios';
import { gql } from '../../__generated__';

export async function fetchCities(name?: string) {
  const res = await client.query({
    query: gql(`
      query Cities(
        $search: String
      ) {
        cities(
          search: $search
        ) {
          id
          name
          region {
            id
            name
            district {
              id
              name
            }
          }
        }
      }
    `),
    variables: {
      search: name
    },
  });
  return res.data.cities;
}

export async function fetchInitialCity() {
  const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/city`);

  return res.data.city;
}