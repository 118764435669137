import { FormikProps } from 'formik';
import styles from './SuggestSelect.module.scss';

type Props<T> = {
  type: 'radio' | 'checkbox';
  withInput?: boolean;
  options: string[];
  field: keyof T;
  formik: FormikProps<T>;
}

const SuggestSelect = <T, >({ type, withInput = false, options, formik, field }: Props<T>) => {
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    if (type === 'checkbox') {
      const currentValue = formik.values[field] as unknown as {label: string, value: string[]};
      if (checked) {
        formik.setFieldValue(String(field), {...formik.values[field], value: [...currentValue.value, value]});
      } else {
        formik.setFieldValue(String(field), {...formik.values[field], value: currentValue.value.filter(v => v !== value)});
      }
    } else {
      formik.setFieldValue(String(field), {...formik.values[field], value: [value]});
    }
  };

  const handleChangeOther = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(String(field), {...formik.values[field], other: e.target.value});
  };

  return (
    <div className={styles.container}>
      <div className={styles.options}>
        {options.map((option) => (
          <div
            className={styles.option}
            key={option}
          >
            <input
              type={type}
              id={option}
              name={String(formik.values[field])}
              value={option}
              checked={(formik.values[field] as any)['value'].includes(option)}
              onChange={handleChange}
            />
            <label htmlFor={option}>{option}</label>
          </div>
        ))}
      </div>
      {withInput && <input onChange={handleChangeOther} placeholder={'Другое'} type="text" className={styles.input} />}
    </div>
  );
};

export default SuggestSelect;