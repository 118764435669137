import React from 'react';
import styles from './HelpMailModalOrder.module.scss';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useLazyQuery } from '@apollo/client';
import { TextAreaField } from '../../inputs/TextField/TextAreaField';
import { Button } from '../../Button/Button';
import { ASK_HELP_ORDER } from '../../../api/public/cabinet/orders';

type Props = {
  orderId: string;
}

type Values = {
  message: string;
}

const ValidationSchema = Yup.object().shape({
  message: Yup.string().required('Обязательное поле')
});

const initialValues: Values = {
  message: ''
};

const HelpMailOrder = (props : Props) => {
  const { orderId } = props;

  const [askHelp, {data : isMailSended}] = useLazyQuery<any, {orderId: string, message: string}>(ASK_HELP_ORDER, {
    onError: (err) => console.error(err),
    fetchPolicy: 'no-cache'
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      askHelp({variables: {message: values.message, orderId}});
    }
  });

  return (
    <div className={styles.confirmModal}>
      {!isMailSended ? 
      <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h4 style={{ alignSelf: 'flex-start', marginTop: 0 }}>Текст вопроса</h4>
          <TextAreaField
            name='message' 
            onChange={formik.handleChange}
            value={formik.values.message} 
          />
          {formik.errors.message && <div className={styles.error}>{formik.errors.message}</div>}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button disabled={!formik.values.message.length} onClick={formik.handleSubmit} title='Отправить' variant='primary' />
        </div>
      </> 
      : 
      <div>
        <div>Мы получили ваш запрос.</div>
        <div>Менеджер свяжется с Вами в ближайшее время.</div>
      </div>
      }
    </div>
  );
};

export default HelpMailOrder;