import Image from 'next/image';
import { useRouter } from 'next/router';
import styles from './ProductSearch.module.scss';

export function ProductSearch() {
  const router = useRouter();
  const { q } = router.query || '';
  return (
    <div className={styles.searchContainer}>
      <form action="/catalog/search" method="get">
        <input placeholder="Поиск" name="q" defaultValue={q} />
        <button type="submit">
          <Image src="/svg/arrow-right.svg" width={24} height={24} alt="" />
        </button>
      </form>
    </div>
  );
}
